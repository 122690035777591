// Created By Ahmed Elshahhat - facebook.com/elshahat


// Media mixins
$tablet-width: 768px;
$desktop-width: 992px;
$desktop-lg-width: 1200px;

@mixin mobile {
	@media (max-width: #{$tablet-width}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin desktop-sm {
	@media (min-width: #{$desktop-width}) and (max-width: #{$desktop-lg-width - 1px}) {
		@content;
	}
}

@mixin desktop-lg {
	@media (min-width: #{$desktop-lg-width}) {
		@content;
	}
}

// Links Mixins
@mixin links-list-h($padding-v:10px, $padding-h:10px, $font-size:inherit, $height:auto, $reponsive:false) {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		display: inline-block;
		@include margin-left(-5px);
		> a {
			display: inline-block;
			width: 100%;
			text-decoration: none;
			padding: $padding-v $padding-h;
			margin: 0;
			height: $height;
			font-size: $font-size;
			@if ($padding-v==0) {
				line-height: $height*0.96;
			}
		}
	}
}

// list - to clear defaults or set custom bullets
@mixin list($padding:'', $bullets:'') {
	@if $padding == clearPadding {
		padding: 0;
	}
	@if $bullets == clearBullets {
		list-style-type: none;
	} @else {
		li {
			&:before {
				content: $bullets;
			}
		}
	}
}

// Separator Mixin
@mixin vertical-sep($height:auto, $color:black, $remove-first:false, $shadeOrTint:shade, $percent:90%, $removeOnHover:false, $width:1px, $position:absolute) {
	@if $position == absolute {
		position: relative;
		&:before {
			@include left(0);
		}
	}
	&:before {
		position: absolute;
		top: 50%;
		@include ltr {
			@include transform(translate(-50%, -50%));
		}
		@include rtl {
			@include transform(translate(50%, -50%));
		}
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: $height;
		border-left: $width solid $color;
		@if $shadeOrTint == shade {
			border-right: $width solid shade($color, $percent);
		}
		@if $shadeOrTint == tint {
			border-right: $width solid tint($color, $percent);
		}
	}
	&:first-child:before {
		@if ($remove-first==true) {
			display: none;
		}
	}
	@if $removeOnHover {
		&:hover:before,
		&:hover + *:before,
		&.active + *:before,
		&.active:before {
			visibility: hidden;
		}
	}
}

// Remove text mixin
@mixin remove-text() {
	text-indent: -99999px;
}

// Bottom-shadow Mixin
@mixin bottom-shadow() {
	@include prefixer(box-shadow, 0 2px 11px rgba(43, 43, 43, 0.47), spec webkit moz)
}

// Mixins for creating overlays on fly :)
@mixin overlay($color:rgba(255, 255, 255, 0.5), $gradient: null, $direction:top) {
	&,
	& > * {
		position: relative;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		@include left(0);
		width: 100%;
		height: 100%;
		background: $color;
	}
	@if ($gradient != null) {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			opacity: 0.6;
			z-index: -1;
			width: 100%;
			height: 100%;
			@include linear-gradient(to $direction, $gradient, transparent, $fallback: transparent);
		}
	}
}

// Buttons mixin - Create any button on fly
@mixin button($btn-color:'', $btmGrade:'5%', $topGrade:'5%', $effect3D:'30%') {
	border: 1px solid shade($btn-color, 5%) !important;
	@include linear-gradient(to top, shade($btn-color, $btmGrade), tint($btn-color, $topGrade), $fallback: $btn-color);
	@include prefixer(box-shadow, inset 0 1px 0 tint($btn-color, $effect3D) !important, spec webkit moz);
	&:hover {
		background: shade($btn-color, 20%) !important;
		@include prefixer(box-shadow, none !important, spec webkit moz);
	}
	&:focus,
	&:active {
		@include prefixer(box-shadow, inset 0 2px 3px shade($btn-color, $effect3D) !important, spec webkit moz);
	}
	&:before {
		background-color: shade($btn-color, 50%);
	}
}

// Shadow Rise on hover
@mixin shadow($perc:'') {
	// Usage Without %
	@include transition(all 0.35s);
	&:hover {
		@include prefixer(box-shadow, 0 0 10px rgba(0, 0, 0, $perc/100), spec webkit moz);
	}
}

// Responsive when needed for mobile
@mixin font-respo($size, $mediaSize:640px) {
	@if ($size) {
		font-size: $size;
		@media (max-width: $mediaSize) {
			font-size: modular-scale(-1, $size);
		}
	}
}

// This mixin is for any sizing rule need responsive less than 640px
// mak sure that $value is one absolute item
@mixin mobile-respo($rule, $value) {
	#{$rule}: $value;
	@media (max-width: 640px) {
		#{$rule}: modular-scale(-1, $value);
	}
}

// This mixin is to center items vertically or horizontally
@mixin center($direction:all, $otherTransforms:null) {
	position: absolute;
	@if $direction == all {
		top: 50%;
		left: 50% !important;
		@include transform(translateY(-50%) translateX(-50%) $otherTransforms);
	}
	@if $direction == vertical {
		top: 50%;
		@include transform(translateY(-50%) $otherTransforms);
	}
	@if $direction == horizontal {
		left: 50% !important;
		@include transform(translateX(-50%) $otherTransforms);
	}
	@if $direction == none {
		position: inherit;
		top: auto;
		left: auto;
		@include transform(translateY(0) translateX(0) $otherTransforms);
	}
}

@mixin title-bar($barColor:#ddd, $barWidth:'', $barThick:4px, $line:'') {
	position: relative;
	&:before,
	&:after {
		position: absolute;
		bottom: 0;
		content: '';
	}
	@if $line == showLine {
		&:before {
			width: 100%;
			height: 2px;
			background-color: #e7e7e7;
			left: 0;
			@include transform(translateY(50%));
		}
	}
	&:after {
		width: $barWidth;
		@if $barThick != null {
			height: $barThick;
		}
		left: 50%;
		background-color: $barColor;
		@include transform(translateX(-50%) translateY(50%));
		border-radius: 2px;
		@include transition();
	}
}

// Padding - Margins
@mixin padding-v($px) {
	padding-top: $px;
	padding-bottom: $px;
}

@mixin padding-h($px) {
	padding-left: $px;
	padding-right: $px;
}

@mixin margin-v($px) {
	margin-top: $px;
	margin-bottom: $px;
}

@mixin margin-h($px) {
	margin-left: $px;
	margin-right: $px;
}

// Create classes for colors
@mixin clrClass($colors) {
	@each $color, $val in $colors {
		.color-#{$color} {
			color: ($val)
		}
	}
}

// Create checklist boxes shapes
@mixin checkbox($fontsize:20px,$icon:'\ea53',$color:#686b69,$checkicon:'\ea52',$checkcolor:$green,$txtdecoration:line-through) {
	input[type='checkbox'] {
		& + label {
			&:before {
				font-size: $fontsize;
				content: $icon;
				color: $color;
			}
		}

		&:checked + label {
			text-decoration: $txtdecoration ;
			> a{
				text-decoration: $txtdecoration !important;
			}
			color: $gray;
			&:before {
				content: $checkicon;
				color: $checkcolor;
			}
		}
	}
}