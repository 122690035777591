
.text {
	&-primary {
		color: $green;
	}
}

.font-tahoma {
	font-family: Tahoma, $font-primary;
	line-height: 25px;
}

@include selection {
	background-color: rgba($green, 0.8);
	color: #FFF;
}

a:not(.btn) {
	color: $green;
	@include transition(all 0.2s);
	text-decoration: none !important;
	&:hover {
		color: $blue !important;
	}
}

.icon-lg {
	font-size: 140%;
}

.highlighted {
	@include prefixer(box-shadow, 1px 1px 1px 0px $gray, spec moz webkit);
	padding: 0 4px;
	margin: 0 5px;
	background-color: #fff;
}

.table-no-borders {
	tr:first-child {
		td {
			border-top: 0;
		}
	}
}

small.color-gray{
	color: lighten($gray,15%);
}