.user-card {
	display: block;
	@include clearfix;
	&__avatar {
		@include margin-left(10px);
		@include float(right);
		@include size(40px);
		font-size: 18px;
		@include border-radius(50%);
		@include transition(all 0.2s);
		line-height: 42px;

		background-color: $green;
		text-align: center;
		position: relative;
		overflow: hidden;

		color: #fff;

		img {
			@include center(all);
			@include transition(all 0.2s);
			@include border-radius(50%);
		}
	}

	&:hover &__avatar {
		//background-color: $blue;
		//border-color: $blue;
		//color: #FFF;
		//img{
		//	@include prefixer(opacity, 0.65, spec moz webkit);
		//}
	}

	&__count, &__name {
		line-height: 18px;
		display: inline-block;
	}
	&__name {
		font-weight: bold;
	}
	&__count, &__add {
		font-size: 90%;
		color: rgba($green, 0.90);
		.icon {
			display: inline-block;
			@include margin-left(5px);
			vertical-align: middle;
			font-size: 140%;
		}
	}

	&--large {
		margin-bottom: 10px;
		min-width: 250px;
		.user-card__avatar {
			@include size(65px);
			font-size: 32px;
			line-height: 70px;
			border: 1px solid $green;
		}
	}
}

.list-tools {
	@include list(clearPadding, clearBullets);
	margin-bottom: 0;
	@media(min-width: 768px) {
		padding-top: 25px;
		text-align: center;
	}

	li {
		&, a {
			display: inline-block;
		}
		margin: 5px 3px 0 3px;
		@media(max-width: 768px) {
			margin: 5px 2px 0 2px;
		}
	}

	.icon {
		@include border-radius(50%);
		border: 1px solid $gray;
		color: $dark-gray;
		display: block;
		text-align: center;

		@include size(40px);
		line-height: 38px;
		font-size: 28px;
	}

	a {
		&:hover {
			.icon {
				color: $green;
				border-color: $green;
			}
		}

		&.delete {
			&:hover {
				.icon {
					color: $red;
					border-color: $red;
				}
			}
		}
	}
}

.list-counters {
	font-size: 15px;
	&, a {
		color: $gray;
	}
	.likes {
		a {
			display: inline-block;
			&:active {
				@include transform(scale(1.15));
			}
			&.liked {
				color: $green;
			}
		}
	}
	@include clearfix;
	@media(min-width: 768px) {
		padding-top: 50px;
	}
	@media(max-width: 768px) {
		padding-top: 23px;
	}
	> span {
		@include float(left);
		@include margin-right(15px);

		&:last-child {
			margin: 0;
		}
	}
	.icon {
		@include margin-right(5px)
	}
}

// Share icons (AddToAny Markup)
.share-icons {
	li {
		display: inline-block;
		.a2a_svg {
			padding: 3px;
			@include border-radius(50%);
		}

		a {
			padding: 0 1px;
		}
	}

	@media(max-width: 992px) {
		margin-top: 20px;
	}
}

// Avatar Selector
.avatar-selector {
	@include border-radius(50%);
	@include size(250px);
	margin: 80px auto 30px;
	display: block;
	cursor: pointer;
	background-color: rgba($gray, 0.3);
	position: relative;
	@include transition(all 0.2s);

	&:hover {
		background-color: rgba($gray, 0.6);
	}

	input[type='file'] {
		visibility: hidden;
	}

	img {
		@include center(all);
		width: 100px;
		margin-top: -20px;
		@include prefixer(opacity, 0.5, spec moz webkit);
		@include transition(all 0.2s);

		&.uploaded {
			width: 100%;
			@include prefixer(opacity, 1, spec moz webkit);
			margin: 0;
			@include border-radius(50%);

			& + span {
				@include prefixer(opacity, 0, spec moz webkit);
				pointer-events: none;
				@include transition(all 0.2s);
			}

			&:hover {
				@include prefixer(opacity, 0.3, spec moz webkit);
				& + span {
					@include prefixer(opacity, 1, spec moz webkit);
					color: #fff;
					font-weight: normal;
					text-shadow: 1px 1px 2px #545454;
				}
			}
		}
	}

	span {
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 14px;
		bottom: 45px;

	}
}

.icon-404 {
	font-size: 200px;
	color: darken(#cca302, 5%);
	@include prefixer(text-shadow, 1px 1px 1px #FFF, spec moz webkit);
}


// Modern loader
$loader-green: #008744;
$loader-blue: #0057e7;
$loader-red: #d62d20;
$loader-yellow: #ffa700;
$loader-white: #eee;

.loader {
	position: relative;
	margin: 0 auto;
	width: 35px;
	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
}

.circular {
	animation: rotate 2s linear infinite;
	height: 100%;
	transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	stroke-linecap: round;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes color {
	100%,
	0% {
		stroke: $loader-green;
	}
	40% {
		stroke: $loader-green;
	}
	66% {
		stroke: $loader-green;
	}
	80%,
	90% {
		stroke: $loader-green;
	}
}