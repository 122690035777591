// Inputs
#{$all-text-inputs}:not(textarea) {
	border: 1px solid rgba($gray,0.2);
	outline: none !important;
	height: 38px;
	padding: 5px 15px;
	vertical-align: middle;
	@include prefixer(box-shadow, none, spec moz webkit);
	font-weight:500;

}

#{$all-text-inputs-focus},
#{$all-text-inputs-hover} {
	border: 1px solid $green;
	@include prefixer(box-shadow, none, spec moz webkit)
}

#{$all-text-inputs-active} {
	//border: 1px solid #00f;
}


@include placeholder{
	color: rgba($gray,0.7);
}

// Groups
.input-group {
	display: block;
	[class*="col-"] {
		margin: 10px 0 10px;
		@include clearfix;
		@media(min-width: 640px) {
			padding: 0;
		}
	}
}

.form{
	margin: 30px 0 15px;

	&--lg{
		#{$all-text-inputs},.btn{
			height: 60px;
			padding: 10px 20px;


			@media(max-width:768px){
				height: 50px;
			}
		}

		.form-group{
			margin: 20px 0;
		}

		.btn{
			width:100%;
			margin: 20px 0;
		}
	}
	.form-control{


		&.error{
			border-color: $red;
		}
	}


	.url-label{
		font-size: 18px;
		line-height: 55px;
		color: $gray;
		font-weight:500;
	}

}

.form-group,.title-input{
	position: relative;
	label.error,span.ValidationError{
		position: absolute;
		padding:8px 12px;
		background-color: rgba($red,1);
		color: #FFF !important;
		@include border-radius(3px);
		font-weight: 300;
		font-size: 13px;
		top:-65%;
		@include left(10px);

		&:before{
			content: '\f0d7';
			font-family: $icon-font;
			position: absolute;
			@include left(20px);
			bottom:-20px;
			font-size: 26px;
			color: rgba($red,1);
		}
	}
}