.listing {
	&__title {
		border-bottom: 1px solid rgba($gray, 0.25);
		padding-bottom: 12px;
		margin-bottom: 0;
		font-size: 16px;
		.icon {
			@include margin-left(7px)
		}
	}

	.hero {
		margin-top: 30px;
		margin-bottom: 50px;

		.title {
			margin-top: 0;
			a {
				color: $dark;
			}
		}
	}



	a:hover {
		.image {
			background-color: $blue;
			.icon {
				@include center(all, scale(1.3));
			}
			&:before, &:after {
				@include prefixer(opacity, 1, spec moz webkit);
			}
			&:after {
				@include center(all, scale(1.3));
			}
		}
		.title {
			color: $blue;
		}

	}
}

.most-popular, .most-active,.last-added {
	margin-bottom: 40px;
}

.user-list {
	margin-top: 20px;
	li{
		padding: 10px 0;
		@media(max-width:992px) {
			@include float(right);
			width: 33.33%;
		}
		@media(max-width:640px) {
			width: 50%;
		}
		@media(max-width:460px) {
			width:100%;
		}
	}
}



.loadMore {
	max-width: 300px;
	margin: 0 auto;
	text-align: center;
	position: relative;

    .loading-indicator {
        height: 27px
    }

    .blob {
        width: 2rem;
        height: 2rem;
        background: $green;
        border-radius: 50%;
        position: absolute;
        left: calc(50% - 1rem);
        top: 0;
        box-shadow: 0 0 1rem rgba(255,255,255,.15)
    }

    .blob-0 {
        -ms-animation: animate-to-0 1.5s infinite;
        animation: animate-to-0 1.5s infinite
    }

    .blob-1 {
        -ms-animation: animate-to-1 1.5s infinite;
        animation: animate-to-1 1.5s infinite
    }

    .blob-2 {
        -ms-animation: animate-to-2 1.5s infinite;
        animation: animate-to-2 1.5s infinite
    }

    .blob-3 {
        -ms-animation: animate-to-3 1.5s infinite;
        animation: animate-to-3 1.5s infinite
    }

    .blob-4 {
        -ms-animation: animate-to-4 1.5s infinite;
        animation: animate-to-4 1.5s infinite
    }

    .blob-5 {
        -ms-animation: animate-to-5 1.5s infinite;
        animation: animate-to-5 1.5s infinite
    }
}




@keyframes animate-to-2 {
	25%,75% {
		transform: translateX(-1.5rem) scale(.75)
	}

	95% {
		transform: translateX(0) scale(1)
	}
}

@keyframes animate-to-3 {
	25%,75% {
		transform: translateX(1.5rem) scale(.75)
	}

	95% {
		transform: translateX(0) scale(1)
	}
}

@keyframes animate-to-1 {
	25% {
		transform: translateX(-1.5rem) scale(.75)
	}

	50%,75% {
		transform: translateX(-4.5rem) scale(.6)
	}

	95% {
		transform: translateX(0) scale(1)
	}
}

@keyframes animate-to-4 {
	25% {
		transform: translateX(1.5rem) scale(.75)
	}

	50%,75% {
		transform: translateX(4.5rem) scale(.6)
	}

	95% {
		transform: translateX(0) scale(1)
	}
}

@keyframes animate-to-0 {
	25% {
		transform: translateX(-1.5rem) scale(.75)
	}

	50% {
		transform: translateX(-4.5rem) scale(.6)
	}

	75% {
		transform: translateX(-7.5rem) scale(.5)
	}

	95% {
		transform: translateX(0) scale(1)
	}
}

@keyframes animate-to-5 {
	25% {
		transform: translateX(1.5rem) scale(.75)
	}

	50% {
		transform: translateX(4.5rem) scale(.6)
	}

	75% {
		transform: translateX(7.5rem) scale(.5)
	}

	95% {
		transform: translateX(0) scale(1)
	}
}



// Pagination
.pagination {
	margin:0 auto ;
	text-align: center;
	display: block;
	table {
		@include ltr{
			direction: rtl !important;
		}
		@include rtl{
			direction: ltr !important;
		}
		a {
			background-color: darken(#eee, 5%);
			color: $green !important;
			display: block;
			line-height: 35px;
			margin: 0 3px;
			height:35px;
			min-width: 35px;
			text-align: center;
			padding: 0 12px;
			@include border-radius(3px);
			font-weight: bold !important;

			&[disabled] {
				color: #91a3af !important;
			}

			&:not([disabled]) {
				&:hover {
					color: #FFF !important;
					background-color: $green;
				}
			}
		}
	}
}