footer {
	background-color: #fff;
	padding: 10px 0;
	min-height: 24px;
	@media(max-width: 500px){
		font-size:11px;
	}
	@media(max-width: 370px) {
		text-align: center;
	}

	p {
		margin: 0;
		color: $gray;
	}

	.footer-links {
		@include float(left);
		@include padding-right(10px);
		margin-bottom: 0;
		min-height: 20px;
		@media(max-width: 370px) {
			@include float(none);
		}

		li {
			display: inline-block;
			@include padding-right(10px);
			@media(min-width: 500px) {
				@include padding-right(20px);
			}
			a {
				color: $gray;
				display: block;
				&:hover {
					color: $green;
				}
			}
		}
	}
}