// snippets to use, extend

// Show effect
.toggleActive{
	visibility: visible;
	@include prefixer(opacity,1,spec moz webkit);
	z-index: 99;
	margin-top: 0;
}


// Thumbs icon
.thumb-icon{
	@include center(all);
	@include transition(all 0.2s);
	@include size(70px);
	@include border-radius(100%);
	background-color: rgba(#fff, 0.15);
	color: #fff;
	font-size: 25px;
	line-height: 70px;
}


// Vertical sep
.vertical-sep{
	&::before{
		position: absolute;
		content: '';
		background-color: rgba($gray,0.3);
		top:5%;
		@include size(1px 90%);
		@include right(-25px)
	}
}