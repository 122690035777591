.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 9px;
	padding-left: 9px;
}

.col-xs-5ths {
	width: 20%;
	@include float(right);
}

@media (min-width: 768px) {
	.col-sm-5ths {
		width: 20%;
		@include float(right);
	}
}

@media (min-width: 992px) {
	.col-md-5ths {
		width: 20%;
		@include float(right);
	}
}

@media (min-width: 1200px) {
	.col-lg-5ths {
		width: 20%;
		@include float(right);
	}
}