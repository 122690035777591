@include font-face("FrutigerLTArabic", "../fonts/FrutigerLTArabic-45Light", 200, $file-formats: eot svg ttf woff);
@include font-face("FrutigerLTArabic", "../fonts/FrutigerLTArabic-55Roman", 500, $file-formats: eot svg ttf woff);
@include font-face("FrutigerLTArabic", "../fonts/FrutigerLTArabic-65Bold", 700, $file-formats: eot svg ttf woff);

@include font-face("NeoSans", "../fonts/NeoSansArabic", 500, $file-formats: eot svg ttf woff);
@include font-face("NeoSans", "../fonts/NeoSansArabic-Bold", 700, $file-formats: eot svg ttf woff);
@include font-face("NeoSans", "../fonts/NeoSansArabic-Bold", 900, $file-formats: eot svg ttf woff);




// Fonts
$font-primary: 'NeoSans';
