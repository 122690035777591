// home intro
.intro {
	margin: 0;
	h2, h3, h4 {
		@include mobile {
			zoom: 0.85;
		}
		line-height: 1.4;
		margin:5px;
	}
	h2 {
		margin-top: 5px;
		@include padding-left(20px);
		font-weight: bold;
		font-size: 27px;

		@include mobile {
			font-size: 24px;
			padding: 0;
		}
	}

	.content {
		padding: 0 0 10px;
	}
}

.features {
	> div {
		text-align: center;
		margin-bottom: 20px;
		.icon {
			font-size: 60px;
			color: $green;
			position: relative;
			&-training {
				font-size: 90px;
				line-height: 0.38em;
				top: 10px;
			}
			&-periority {
				font-size: 72px;
				line-height: 0.7em;
			}
			&-autosave {
				font-size: 70px;
				line-height: 0.7em;
				top: 5px
			}
			&-improve {
				font-size: 68px;
				line-height: 0.7em;
				top: 7px
			}
		}
		h5 {
			color: $gray;
			line-height: 1.3;
		}

	}
}

.steps {
	margin: 15px 0 10px;
	@include clearfix;
	i {
		font-style: normal;
		font-size: 11px;
		color: #FFF;
		font-weight: 700;
		background-color: $green;
		display: inline-block;
		text-align: center;
		line-height: 22px;
		margin-top: 5px;
		@include size(18px);
		@include border-radius(50%)
	}
	img {
		@include float(right);
		@include margin-left(8px);
		margin-bottom: 5px;
	}
	p {
		font-weight: 700;
		font-size: 14px;
		color: $green;
		margin: 0;
	}
	span {
		font-size: 85%;
	}
}


// Slider
.row--slider{
	margin-right: 0;
	margin-left: 0;
	@include mobile{
		margin-left: -9px;
		margin-right: -9px;
	}
}
.swiper-slide{
	min-height: 200px;
	margin-top: 12px;
}
.swiper-pagination-bullet-active{
	background: $green !important;
}