.help-page{
	padding-bottom: 180px;

	> .row{
		margin-top: 20px;
		margin-bottom: 30px;
	}
	.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
		line-height:2;
		@include mobile{
			line-height:1.5;
			font-size: 120%;
		}
	}

	.title-block{
		margin-top: 30px;
		margin-bottom: 0;
		color: $green;
		font-size: 22px;
		font-weight: 700 !important;
		//border-bottom:1px solid $gray;
		.icon{
			vertical-align: middle;
			@include margin-left(7px)
		}
	}
	.text-block{
		img{
			max-width: 360px;
			@include prefixer(box-shadow,0 0 10px #c3c3c3,spec moz webkit);
			@include border-radius(5px);
			padding: 4px;
			margin: 5px 8px;
			&:not(.img-lg){
				max-height: 50px;
				vertical-align: middle;
				display: inline-block;
			}

		}
		margin-bottom: 30px;
	}
	.img-shot{
		margin-bottom: 30px;
	}
	ul{
		line-height:2;
		font-size: 18px;
		color: $gray;
	}

	@include mobile{
		ul{
			font-size: 14px;
		}
	}


	@media (max-width:768px) {
		.pull-right,.pull-left{
			float: none !important;
		}
	}
}