header {
	background-color: #fff;
	padding: 20px 0;
	@media(max-width: 768px) {
		padding: 10px 0;
	}

	.logo {
		display: inline-block;
		text-decoration: none !important;
		position: relative;
		z-index: 1;
		color: $dark-gray;
		@include center(vertical);
		&:hover {
			color: $green;
		}
		img {
			max-width:160px;
			width: 200px;
			display: inline-block;
			@media(max-width: 768px) {
				max-width: 130px;
			}

			.ie &{
				height:50px;
				margin-top: 35px;
			}
		}

		.slogan {
			display: block;
			vertical-align: middle;
			font-size: 10px;
			position: relative;
			bottom: -5px;
		}

		span{
			position: absolute;
			font-size:12px;
			color: #999;
			font-weight: 300;
			top:-5px;
			right:0;
		}
	}

	.navbar {
		margin: 0;
		min-height: 40px;
		position: relative;

		&-form {
			margin: 0;
			padding: 0;
			z-index: 1;
			position: relative;
			@media(min-width:768px){
				@include margin-left(-7%);
			}
			.form-group {
				width: 100% !important;
				position: relative;
				@include mobile{
					height:33px;
				}
				&:before {
					content: '\e90c';
					font-family: $icon-font;
					position: absolute;
					@include right(12px);
					height: 100%;
					line-height: 35px;
					font-size: 20px;
					color: rgba($gray, 0.35)
				}
			}
			input.form-control {
				width: 100%;
				height: 35px;
				@include padding-right(40px);
				@include padding-left(90px)
			}
			.tag-button {
				font-size: 13px;
				line-height: 30px;
				position: absolute;
				height: calc(100% - 2px);
				top: 1px;
				color: $dark-gray;
				background-color: $offwhite;

				@include left(1px);
				@include border-radius(5px);

				@include ltr() {
					@media(min-width: 600px) {
						@include border-radius(3px 0 0 3px);
					}
					padding: 1px 10px 0 6px;
				}
				@include rtl() {
					@media(min-width: 600px) {
						@include border-radius(0 3px 3px 0);
					}
					padding: 1px 6px 0 10px;
				}

				.icon {
					font-size: 18px;
					vertical-align: middle;
					line-height: 30px;
					@include float(left);
					@include margin-right(6px);
					@include transition(all 0.2s);
				}
				&:hover, &.fired {
					color: $green !important;
				}
				&.fired {
					background-color: lighten($gray, 40%);
					.icon {
						@include transform(rotate(180deg));
					}
				}
			}
			.btn {
				position: absolute;
			}
		}
	}

	.addNewList {
		background-color: $green;
		@include margin-right(15px !important)
	}

	.left-icons {
		@include clearfix;
		padding-top: 2px;
		max-height: 39px;
		> * {
			@include margin-right(10px);
			@include transition(all 0.3s);
		}

		// Icons positions on fire search
		&.firesearch {
			& > * {
				overflow: hidden;
			}
			@media(max-width: 600px) {
				> *:not(.search-btn) {
					@include size(0);
					visibility: hidden;
					margin: 0 !important;
				}
			}
		}

		.search-btn {
			&.fired {
				i:before {
					content: '\e907';
				}
			}
		}

		.btn-icon{
			//@media(min-width:1200px){
				@include margin-right(10px);
			//}
			//@media(max-width:1200px){
			//	@include margin-left(8px);
			//}
		}
	}

	// Search-bar Actions on fire search
	.search-bar {
		@media(max-width: 600px) {
			&.firesearch {
				display: block !important;
				width: auto;
				z-index: 1;
				position: absolute;
				@include left(40px);
				@include right(0);

				input.form-control {
					height: 100%;
					border: 0
				}
				.tag-button {
					//top:3px
				}
			}
		}

	}
}

// Tags Box
.tags-box {
	margin: 0;
	padding: 15px 0;
	background-color: darken(#fff, 2%);

	ul {
		margin: 0;
		@include list(clearPadding, clearBullets);

		li {
			display: inline-block;
			padding:0 1px;

			a {
				display: block;
				background-color: #fff;
				font-size: 12px;
				color: $gray;
				@include transition(all 0s);
				@include border-radius(3px);
				@include padding-right(2px);
				overflow: hidden;

				span {
					display: inline-block;
					padding: 2px 3px 1px;
					@include padding-left(2px);
					@include transition(all 0s);
				}
				.count {
					background-color: #ececec;
					@include padding-right(5px);
					@include padding-left(7px)
				}

				&:hover {
					&, .count {
						background-color: $blue;
						color: #FFF !important;
					}
					color: #FFF;
				}
			}
		}
	}

	// In-home categories
	.intro &{
		background-color: transparent;
		li{
			width: 16.3%;
			margin-bottom: 2px;
			@include tablet{
				width: 33%;
			}
			@include mobile{
				width: 49.5%;
			}


			a{
				height:40px;
				@include padding-left(30px);
				position: relative;
				span{
					padding:5px 3px 4px;
					&:first-child{
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						width: 96%;
					}
				}
				.count{
					position: absolute;
					top:0;
					@include left(0);
					min-width: 30px;
					text-align: center;
					height: 100%;
					line-height: 35px;
					background-color: #FFF;
				}
			}
		}
	}
}