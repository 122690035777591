// Reset buttons
button,.btn{
	&:focus, &:active {
		outline: none !important;
		@include prefixer(box-shadow, none !important, spec moz webkit);
	}

	&.open{
		@include float(right);
		border:none;
		background: none;
		font-size:30px;
		color: #000;
		@include prefixer(opacity,0.2, spec moz webkit);
		padding:0;
		margin:0;
		font-weight:700;
		line-height:1;
		@include size(30px);
		position: relative;
		.icon{
			@include center(all);
		}

		&:hover{
			@include prefixer(opacity,0.6, spec moz webkit);
		}
	}
}

.btn {
	//border: 0;
	font-weight: normal;
	outline: none !important;
	@include transition(all 0.1s);

	@media(max-width:450px){
		font-size:11px;
	}

	.icon {
		font-size: 125%;
		vertical-align: middle;
		@media(min-width: 992px) {
			@include margin-left(5px);
		}
		&.invers {
			@include margin-right(6px);
			@include margin-left(0)
		}
	}

	// Colors
	&-primary {
		background-color: $green;
		border-color: $green;

		&:focus,&:hover{
			background-color: $blue;
			border-color: $green;

		}
		&:active{
			background-color: #f6f2f2 !important;
			color:#c2c2c7 !important;
			border-color: #c2c2c7 !important;
		}
	}
	&-border {
		background-color: transparent;
		border-color: #B1ADAC;
		color: #333;
		&:focus,&:hover{
			background-color: #cecece;
			border-color: #EDECEB;
		}
		&:active{
			background-color: #f6f2f2 !important;
			color:#c2c2c7 !important;
			border-color: #c2c2c7;
		}
	}

	&-gray{
		background-color: #cecece;
		color: #333333 !important;
		border-color: #cecece;
		&:focus,&:hover{
			background-color: #cecece;
			border-color: #EDECEB;
		}
		&:active{
			background-color: #f6f2f2 !important;
			color:#c2c2c7 !important;
			border-color: #c2c2c7;
		}
	}

	&-white{
		background-color: #fff;
		color: #666;
		border-color: #eee;
		&:focus,&:hover,&:active,&.active{
			background-color: $green;
			border-color: #EDECEB;
			color: #FFF;
		}
	}

	// Types
	&-icon {
		padding: 0;
		background-color: transparent;
		font-size: 24px;
		@media(max-width: 1200px){
			@include size(30px);
		}
		display: inline-block;
		.icon {
			margin: 0;
			line-height: 29px;
			vertical-align: top;
		}
		span{
			font-size: 14px;
			vertical-align: top;
			line-height: 30px;
		}
	}
	&-circle {
		border-radius: 50%;
		background-color: $gray;
		color: #fff;
		text-align: center;
		padding: 0;
		font-size: 16px;

		&:hover {
			color: #FFF;
			background-color: $dark-gray;
		}
	}

	// Sizes
	&-lg {
		.icon {
			position: relative;
			top: -1px
		}
	}
}

.btn-group{
	.btn{
		padding: 6px 8px;
	}
}

.dropdown-menu {
	@include prefixer(box-shadow, 0 1px 2px 0px rgba($gray, 0.2), spec moz webkit);
	border-color: rgba($gray, 0.2);

	&--arrow {
		right: auto !important;
		@include center(horizontal);
		top: 100%;
		margin-top: 15px;
		&:before {
			content: '\f0d8';
			font-family: $icon-font;
			@include center(horizontal);
			top: -25px;
			font-size: 28px;
			color: #FFF;
			@include prefixer(text-shadow, 0 -1px 0 rgba($gray, 0.6), spec moz webkit);

			@media(max-width: 640px) {
				position: absolute;
				@include left(35px !important);
				@include right(auto !important);
			}
		}

		@media(max-width: 640px) {
			@include left(-20px !important);
			@include right(auto !important);
			@include center(none);
			position: absolute;
		}

		li {
			a {
				padding: 6px 15px;
				&:hover {
					background-color: $blue;
					color: #FFF !important;
				}
			}
		}

		.icon {
			font-size: 120%;
			vertical-align: middle;
			@include margin-left(5px);
			display: inline-block;
			min-width: 15px;
			text-align: center;
		}
	}
}


// Custom icons
//