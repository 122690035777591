// Colors
$offwhite: #f8f8f8;
$gray: #8e908f;
$dark: #333333;
$dark-gray: #686b69;
$blue: #27864d;
$green: #3a9c61;
$red:#ca4444;

$colors: ('offwhite', #f8f8f8),
		('gray', #8e908f),
		('dark', #333),
		('dark-gray', #686b69),
		('blue', #27864d),
		('green', #3a9c61),
		('red', #ca4444);



@include clrClass($colors);

// Fonts
$icon-font: 'icomoon';