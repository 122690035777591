.list {
	background-color: #fff;
	min-height: 100px;
	padding: 15px 10px 30px;
	border: 1px solid #ebebeb;
	margin: 15px 0;
	@include prefixer(user-select, none, spec moz webkit ms);
	@include clearfix;

	@media(max-width: 640px) {
		padding: 10px 5px;
	}
	&__head {
		@include clearfix;
		@media(max-width: 992px) {
			@include clearfix;
			.list-title * {
				@include text-align(right !important);
			}
		}
		[data-alignment='rtl'] & .list-title * {
			@media(max-width: 992px) {
				text-align: right !important;

			}
		}
		[data-alignment='ltr'] & .list-title * {
			@media(max-width: 992px) {
				text-align: left !important;
			}
		}

		textarea {
			border: 0 !important;
			text-align: center;
			outline: none;
			resize: none;
			margin: 0;
			vertical-align: top;
		}

		.list-header {
			@media(min-width: 992px) {
				float: none;
			}
		}

		.list-title {
			text-align: center;
			margin: 0;
			font-size: 30px;

			@media(max-width: 640px) {
				font-size: 20px;
			}

			// Title Validation
			span.ValidationError {
				top:8px;
				z-index: 9;
				@include center(horizontal);
				@include left(auto !important);
				@include right(60% !important);
				@include tablet{
					@include right(20% !important);
				}
				@include mobile{
					@include right(20% !important);
					top:3px;
				}

				&:before{
					@include right(-10px);
					top: 0;
					transform: rotate(-90deg);
					bottom: auto;
					@include left(auto)
				}
			}

			.title-input {
				font-size: 100%;
				margin: 0;
			}
			.desc-input {
				font-size: 60%;
				@include prefixer(opacity, 0.7, spec moz webkit);

				label,textarea{
					margin-bottom: 10px;
				}
			}
		}

		.list-controls {
			.selectable {
				display: inline-block;
				position: relative;
				z-index: 1;

				ul {
					@include list(clearPadding, clearBullets);
					li {
						display: inline-block;
					}
				}

				a {
					display: block;
					color: $gray;
					font-size: 18px;
					padding: 8px 12px;
					&.selected {
						background-color: $offwhite;
					}
					.icon {
						vertical-align: middle
					}

					@media(max-width: 1200px) {
						padding: 5px 8px;
					}
				}

				input[type=submit] {
					display: none;
				}
			}
		}
	}

	&__img {
		text-align: center;
		padding: 0 10px;
		.upload_box {
			padding: 10px 5px;
			border: 1px dashed lighten($gray, 20%);
			@include border-radius(4px);
			max-width: 400px;
			margin: 0 auto 10px;
			font-family: Tahoma, "BahijTheSansArabic";

			label {
				font-size: 12px;
				font-weight: 300;
				color: lighten($gray, 15%);
				cursor: pointer;
				margin: 0;
				.icon {
					font-size: 25px;
					@include margin-left(5px)
				}
			}
			input {
				text-align: center;
				display: none;
			}
			.btn {
				margin-top: 5px;
			}
			.close-btn {
				@include margin-right(10px);
				font-size: 22px;
			}
		}

		.image_box {
			margin: 0 -20px;
			@media (max-width: 640px) {
				margin: 0 -15px;
			}
			min-height: 350px;
			@media (max-width: 992px) {
				min-height: 250px;
			}
			@media (max-width: 640px) {
				min-height: 150px;
			}
			position: relative;
			overflow: hidden;
			img {
				width: 100%;
				@include center(all);
				@include mobile{
					width: 120%;
				}
			}

			.delete-btn{
				position: absolute;
				top:20px;
				z-index: 1;
				color: $red;
				font-size: 24px;
				display: block;
				line-height:2;
				background-color: rgba(#eee,0.8);
				@include right(20px);
				@include size(40px);
				@include border-radius(50%);
			}
		}
	}

	&__body {
		@include clearfix;
	}

	.list-item {
		@include clearfix;
		position: relative;
		overflow: hidden;


		//@media(max-width: 640px) {
		//	min-height: 40px;
		//}
		> * {
			padding: 5px;
		}

		textarea {
			width: 100%;
			max-height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		label {
			//min-height:50px;
			position: relative !important;
			z-index: 1;
			float: left;
			margin: 0;
			margin-left: 50%;
			@include transform(translateX(-50%) scale(1));
			@include transition(all 0s);

			width: 100%;

			height: 100%;
			overflow: hidden;

			color: darken($dark-gray, 10%);
			font-weight: normal;

			visibility: visible;
			background-color: #fff;

			&.placeholder {
				color: $gray;
			}

		}

		&.edit {
			label {
				@include transition(all 0s);
				display: none;
				@include transform(translateX(-50%) scale(0));
				height: 0;
				z-index: 1;
			}

			textarea {
				height: 50px;
				color: rgba(#000, 0.9) !important;
				position: relative;
				z-index: 9;
			}
		}

		&.clickable-item {
			label {
				cursor: pointer;
			}
		}

	}
}

// List Attributes
.listBody {
	&[data-edit="allow"] {
		.chkbox_list {
			> div:hover {
				.subtitle {
					visibility: visible;
					opacity: 1;
				}
			}
			@media(max-width: 768px) {
				.subtitle {
					visibility: visible;
					opacity: 1;
				}
			}
		}

	}

	// Directions
	&[data-alignment='rtl'] {
		.chkbox_list {
			text-align: right;
			direction: rtl;
			label {
				padding-right: 32px;
				&:before {
					right: 0;
				}
				a {
					&:before {
						margin-left: 4px;
					}
				}
			}
			.click-over {
				padding-left: 0;
				padding-right: 32px;
			}
			> div {
				padding-right: 5px;
				padding-left: 35px;
				.subtitle {
					left: 1px;
				}
			}
		}

	}
	&[data-alignment='ltr'] {
		.chkbox_list {
			text-align: left;
			direction: ltr;
			label {
				padding-left: 32px;
				&:before {
					left: 0;
				}
				a {
					&:before {
						margin-right: 4px;
					}
				}
			}
			.click-over {
				padding-right: 0;
				padding-left: 32px;
			}
			> div {
				padding-left: 5px;
				padding-right: 35px;
				.subtitle {
					right: 1px;
				}
			}
		}
	}

	// Shapes
	&[data-shape="link"] {
		.chkbox_list {
			@include checkbox(22px, '\e9cb', rgba($gray, 0.3), '\e9cb', $green);
		}
	}
	&[data-shape="default"] {
		.chkbox_list {
			@include checkbox();
		}
	}
	&[data-shape="circle"] {
		.chkbox_list {
			@include checkbox(26px, '\e90b', rgba($gray, 0.3), '\e906', $green);
		}
	}
	&[data-shape="bullets"] {
		.chkbox_list {
			@include checkbox(21px, '\ea56', $gray, '\ea54', $green);
		}
	}

}

.list_wrapper {
	margin: 20px;
	@include mobile {
		margin: 20px 2px;
	}
}

.chkbox_list {
	color: $dark-gray;
	@media(max-width: 768px) {
		@include prefixer(zoom, 0.85, spec moz webkit);
		-moz-transform: scale(0.85); /* Firefox */
	}

	input[type='checkbox'] {
		display: none;

		// Check box on label active
		& + label {
			&:active {
				&::before {
					@include transform(scale(0.85));
				}
			}
		}
	}

	& > div.edit-active {
		background-color: rgba($gray, 0.08);
		label {
			position: absolute;
			@include size(0 35px);
			overflow: hidden;
		}
	}

	label {
		font-size: 16px;
		font-weight: normal;
		line-height: 27px;
		cursor: pointer;
		position: relative;
		margin: 0;
		text-align: justify;
		word-wrap: break-word;

		// Checkbox base style
		&:before {
			position: absolute;
			font-family: $icon-font;
			content: '';
			font-size: 20px;
			width: 22px;
			line-height: 27px;
			text-align: center;
			//@include center(vertical);
			@include transition(all 0.2s);
		}

		a {
			display: inline-block;
			@include border-radius(50px);
			border: 1px solid $green;
			font-size: 80%;
			margin: 0 2px 0;
			padding: 1px 8px;
			line-height: 16px;
			z-index: 10;
			position: relative;
			&:before {
				content: '\e9cb';
				font-family: $icon-font;
				vertical-align: middle;
				text-decoration: none !important;
			}
			&:hover {
				background-color: $green;
				color: #FFF !important;
			}
		}
	}

	> div {
		position: relative;
		overflow: hidden;
		padding: 3px 0;
		@include transition(all 0.2s);

		&:hover {
			background-color: rgba($gray, 0.08);
		}

		// Click-over to trigger edit event
		.click-over {
			position: absolute;
			top: 0;
			cursor: text;
			@include left(30px);
			@include right(30px);
			@include size(auto 100%);

			&.edit-active {
				//background-color: rgba($offwhite,1);
				z-index: 11;
				position: initial;

			}

			textarea {
				padding: 0;
				width: 100%;
				vertical-align: top;
				height: 25px;
				outline: 0 none transparent !important;
				border: none !important;
				font-size: 16px;
				line-height: 27px;
				color: $green;
				text-align: justify;
				resize: none;
				background-color: transparent;
				-webkit-appearance: none;
			}
		}

		.subtitle {
			opacity: 0;
			z-index: 2;
			font-size: 22px;
			line-height: 22px;
			color: darken($offwhite, 20%);
			margin: 0 5px;
			@include transition(all 0.2s);
			@include center(vertical);
			@media(max-width: 768px) {
				margin: 0;
			}
		}

		&.subtitled {
			margin-top: 10px;

			label {
				color: darken($dark-gray, 10%) !important;
				padding: 0 !important;
				font-size: 21px;
				line-height: 28px;
				text-decoration: none !important;
				&:before {
					color: darken($dark-gray, 10%) !important;
					content: "\e902" !important;
					font-size: 24px !important;
					display: none;
				}
			}

			.click-over, textarea {
				font-size: 21px;
				line-height: 28px;
				padding: 0 !important;
				left: 5px !important;
				right: 5px !important;
			}
		}
	}

}

input.list-item-add {
	border: 0;
	border-bottom: 1px solid rgba($gray, 0.5);
	margin: 15px 0 0;
	width: 100%;
	font-size: 17px;
	padding-right: 0;
	padding-left: 0;
	&:hover {
		border: 0;
		border-bottom: 1px solid $gray;
	}
	&:focus {
		border: 0;
		border-bottom: 1px solid $green;
		color: $green;
	}

	[data-alignment='rtl'] & {
		text-align: right;
	}
	[data-alignment='ltr'] & {
		text-align: left;
	}
}

// Save buttons
.save-buttons {
	select {
		display: block;
		@include padding-left(20px);
		margin-bottom: 8px;
		padding: 0 12px
	}

	.btn {
		margin-bottom: 8px;
		display: block;
		width: 100%
	}

	.checkbox {
		margin-top: 7px;
	}
}

// Sortable
.sortable-ghost {
	opacity: 0.2;
	background-color: #fff;
}

// Dimmed style
.dimmed {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		right: 0;
		@include size(100%);
		background-color: rgba(#fff, 0.5);
		z-index: 99;
	}
}

.publishing-tags {
	margin: 5px 0;
	font-size: 14px;
}

// Alerts
.alert{
	padding: 8px 15px;
	min-height: 30px;
	@include transform(translate3d(0,0,0));
	@include border-radius(0);
	@include mobile{
		width: 95%;
	}

	> .container{
		position: relative;
	}

	p{
		margin: 0;
		@include padding-left(10px);
		@include mobile{
			font-size: 90%;
		}
	}
	.close{
		position: absolute;
		@include left(0);
		top:-2px;
		color: $red;
		@include prefixer(opacity,0.5,spec moz webkit);
	}

	.icon{
		vertical-align: middle;
	}
	&-fixed{
		width:100%;
		@include center(horizontal);
		@include transition(top 0.5s ease-out);
		position: fixed !important;
		//-webkit-backface-visibility: hidden;
		top:-100%;
		z-index: 99;
		@include prefixer(box-shadow,0 3px 8px rgba(#000,0.2),spec moz webkit);

		&.shown{
			top:0;
		}
	}

	&-warning{
		background-color:rgba(255, 238, 145, 0.95);
	}
}


.sync{
	position: fixed;
	//display: none;
	@include transform(scale(0) translate3d(0,0,0));
	zoom:0;
	@include transition(all 0.25s);
	@include size(50px);
	@include left(50%);
	@include margin-left(-25px);
	@include border-radius(50%);
	@include prefixer(opacity,0.95,spec moz webkit);
	@include prefixer(box-shadow,0 0 20px rgba(#000,0.3),spec moz webkit);
	top:55px;

	z-index:101;
	text-align: center;
	line-height: 45px;
	background-color:#FFF;
	color: $green;
	font-size:20px;



	&.active{
		//display: block;
		zoom:1;
		@include transform(scale(1) translate3d(0,0,0));

		&.done {
			.loader{
				visibility: hidden;
				@include prefixer(opacity,0,spec moz webkit);
			}
			.finish{
				visibility: visible;
				@include prefixer(opacity,1,spec moz webkit);
			}
		}
	}

	.loader{
		@include center(all);
		@include transition(all 0.2s);
	}
	.finish{
		visibility: hidden;
		@include transition(all 0.2s);
		@include prefixer(opacity,0,spec moz webkit);
		height:100%;
		vertical-align: middle;
		line-height: 48px;

		i{
			vertical-align: middle;
			font-weight: bold;
			font-size: 33px;
		}
	}
}