// Alignment classes
.text-left {
    @include text-align(right);
}
.text-right {
    @include text-align(left);
}
.no-float{
	float: none !important
}


// Padding
.no-padding {
	padding: 0 !important;
}
.p-10 {
	padding: 10px;
}
.p-15 {
	padding: 15px;
}
.p-v-5 {
    @include padding-v(5px)
}
.p-v-10 {
	@include padding-v(10px)
}
.p-v-15{
	@include padding-v(15px)
}
.p-v-20{
	@include padding-v(20px)
}
.p-h-5{
	@include padding-h(5px)
}
.p-h-10{
	@include padding-h(10px)
}
.p-h-15{
	@include padding-h(15px)
}
.p-h-20{
	@include padding-h(20px)
}

.p-r-10{
    @include padding-left(10px);
}
.p-l-10{
    @include padding-right(10px);
}

.no-padding-top {
	padding-top: 0 !important;
}
.no-padding-bottom {
	padding-bottom: 0 !important;
}


// Margins
.no-margin {
	margin: 0 !important;
}
.m-10 {
	margin:10px;
}
.m-15 {
	margin:15px;
}
.m-v-5{
	@include margin-v(5px)
}
.m-v-10{
	@include margin-v(10px)
}
.m-v-15{
	@include margin-v(15px)
}
.m-v-20{
	@include margin-v(20px)
}
.m-h-5{
	@include margin-h(5px)
}
.m-h-10{
	@include margin-h(10px)
}
.m-h-15{
	@include margin-h(15px)
}
.m-h-20{
	@include margin-h(20px)
}

.margin-b-30{
    margin-bottom: 30px;
}
.no-margin-top {
    margin-top: 0 !important;
}
.no-margin-bottom {
    margin-bottom: 0 !important;
}


// Divs - shapes
.relative{
    position: relative;
}
.initial-pos{
	position: initial;
}
.block{
	display: block;
}
.circle{
    @include border-radius(50%);
}
.clearList{
    @include list(clearPadding,clearBullets);
    margin: 0;
}


// BGs - Colors
.bg-transparent{
	background-color: transparent !important;
}
.color-black {
    color: #000 !important;
}
.color-white {
    color: white !important;
}
.color-semi-white{
    color: #c1c1c1;
}


// Text style
.justify {
    text-align: justify;
}
.italic {
    font-style: italic;
}
.underlined{
	border-bottom:1px solid #282828;
	display: inline;
}
.underline{
	text-decoration: underline;
}
.bold,.strong {
    font-weight:700 !important;
}
.bolder {
    font-weight:900 !important;
}
.normal {
    font-weight: 500 !important;
}
.light {
    font-weight: 300 !important;
}
.lowercase {
    text-transform: lowercase !important;
}
.uppercase {
    text-transform: uppercase !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.larger{
	font-size: 120%;
}
.line-height-normal{
    line-height: normal;
}