.thumb {
	@extend .clearfix;
	display: block;
	background-color: #fff;
	margin-bottom: 20px;
	padding-bottom:5px;
	border:1px solid #ededed;
	@include border-radius( 6px);


	.image {
		text-align: center;
		display: block;
		width: 100%;
		position: relative;
		max-height: 180px;
		min-height: 140px;
		overflow: hidden;
		@include border-radius(5px 5px 0 0);
		@include transition(all 0.15s);

		&:not(.image--icon) {
			background:#fbfafa url("../imgs/loading.gif") no-repeat center center;
			&:before {
				content: '';
				display: block;
				position: absolute;
				@include size(100%);
				@include prefixer(opacity, 0, spec moz webkit);
				left: 0;
				background-color: rgba($blue, 0.6);
				z-index: 1;
				@include transition(all 0.2s);

			}
			&:after {
				content: '\e9bb';
				position: absolute;
				font-family: $icon-font;
				@extend .thumb-icon;
				@include prefixer(opacity, 0, spec moz webkit);
				@include transition(all 0.2s);
				z-index: 2;
			}
			&.removeLoader{
				background:#fbfafa;
			}
		}

		&--icon {
			background-color: $green;
			.icon {
				@extend .thumb-icon;
			}
		}

		img {
			height: auto;
			width: auto;
			max-width: initial;
			@include center(all);
			visibility: hidden;

			&.vertical{
				height:100%;
				width:auto;
				visibility: visible;
			}
			&.horizontal{
				height:auto;
				width:100%;
				visibility: visible;
			}
		}
	}

	.title {
		line-height: 1.4;
		font-size: 13px;
		color: $dark-gray;
		padding: 0 10px 0;
		margin-top: 8px;
		min-height: 36px;
		font-weight: 500;
	}

	.meta {
		@include clearfix;
		padding:5px 10px 0;
		margin-top: 0;
		font-size: 90%;
		border-top:1px solid darken($offwhite,1%);
		.author{
			display: block;
			width:100%;
			margin:0 0 5px;
			padding-bottom: 5px;

			@include float(right);

			.icon{
				@include margin-left(6px);
				@include margin-right(0)
			}
		}
		> * {
			color: rgba($gray, 0.7);
			@include float(left);
			margin: 0 5px;
			.icon {
				@include margin-right(4px);
				line-height: 18px;
			}
		}
		.add{
			@include float(right);
			@include margin-right(0);
			.icon{
				@include margin-left(6px);
				@include margin-right(0)
			}
		}
	}
}
