// Overwrite bootstrap grid margins/padding
// Violates bootstrap but perfectly matches design
.container {
	padding-right: 9px;
	padding-left: 9px;
}

// Fixed position scroll fix
.document{
	-webkit-overflow-scrolling: touch;
}

.row {
	margin-right: -9px;
	margin-left: -9px;
}

[class*="col-"] {
	padding-right: 9px;
	padding-left: 9px;
}

body {
	background-color: darken($offwhite, 1%);
	@include linear-gradient(to bottom, darken($offwhite, 2%) 95%, #fff 100%);
	font-family: $font-primary;
	color: #555555;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

main {
	min-height: 800px;
	margin: 25px 0;
	@media(max-width: 768px) {
		margin: 10px 0;
	}
}

section {
	margin: 10px 0 40px;
	@media(max-width: 768px) {
		margin: 5px 0 20px;
	}

	&.form-page {
		margin: 20px auto;
	}

	.page-title {
		color: $green;
		border-bottom: 1px solid rgba($gray, 0.25);
		padding-bottom: 12px;
		font-size: 18px;
		.icon {
			@include margin-left(7px);
			font-size: 22px;
			vertical-align: middle;
		}
	}
}

// Media for printable areas
@media print {
	.document *{
		visibility: hidden
	}

	.list {
		position: absolute;
		top:0;
		width:100%;
		* {
			visibility: visible;
		}

		.list-controls, .subtitle,.list-item-add {
			display: none;
		}
	}
}